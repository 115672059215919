/* eslint-disable */
import "./janus_0.7.2";
import "./online/offline.min.js";
import { BROADCASTING } from "@/constants/services";
import locationIcon from "./../assets/img/akar-icons_location.svg";
import phoneIcon from "./../assets/img/akar-icons_phone.svg";
//Redline  - Ctrl+L

// Caller Icons
let location = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.5 9.1665C9.60457 9.1665 10.5 8.27107 10.5 7.1665C10.5 6.06193 9.60457 5.1665 8.5 5.1665C7.39543 5.1665 6.5 6.06193 6.5 7.1665C6.5 8.27107 7.39543 9.1665 8.5 9.1665Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.49984 1.83325C7.08535 1.83325 5.7288 2.39516 4.7286 3.39535C3.72841 4.39554 3.1665 5.7521 3.1665 7.16658C3.1665 8.42792 3.4345 9.25325 4.1665 10.1666L8.49984 15.1666L12.8332 10.1666C13.5652 9.25325 13.8332 8.42792 13.8332 7.16658C13.8332 5.7521 13.2713 4.39554 12.2711 3.39535C11.2709 2.39516 9.91433 1.83325 8.49984 1.83325Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
let phone = `<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.53583 4.6601L5.2805 2.05677C5.0205 1.75677 4.54383 1.7581 4.24183 2.06077L2.38716 3.91877C1.83516 4.47143 1.67716 5.2921 1.9965 5.9501C3.90424 9.90008 7.08986 13.0902 11.0372 15.0034C11.6945 15.3228 12.5145 15.1648 13.0665 14.6121L14.9385 12.7368C15.2418 12.4334 15.2425 11.9541 14.9398 11.6941L12.3265 9.45077C12.0532 9.2161 11.6285 9.24677 11.3545 9.52143L10.4452 10.4321C10.3986 10.4809 10.3373 10.5131 10.2707 10.5237C10.2041 10.5343 10.1359 10.5227 10.0765 10.4908C8.59013 9.63483 7.35716 8.40025 6.50316 6.91277C6.47118 6.85327 6.4596 6.78492 6.4702 6.71821C6.48079 6.65149 6.51298 6.5901 6.56183 6.54343L7.4685 5.6361C7.74316 5.3601 7.77316 4.9341 7.53583 4.6601Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

let username = "";
let mobile = "";
let userId = "";
let city = "";
let channel = localStorage.getItem("room")
// process.env.VUE_APP_JANUS_CHANNEL_NON_LICENSED;
let broadCastingService = 0;
//const janus_url = "https://host3.scanarchives.com:8089/janus_0.8.1";
const janus_url = "wss://host3.scanarchives.com:443/janus_0.11.3";

if (localStorage.getItem("user_data")) {
  const userData = JSON.parse(localStorage.getItem("user_data"));
  broadCastingService = userData.services.findIndex(
    (val) => val.id === BROADCASTING
  );
  const companyName = userData.parent_id
    ? userData.name
    : userData.user_detail.company_name;
  username = `${companyName} / ${userData.user_detail.representative_name}`;
  const phone = userData.parent_id
    ? userData.user_detail.company_phone
    : userData.user_detail.company_phone;
  const formattedValue = phone
    .replace(/\D/g, "")
    .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  mobile = !formattedValue[2]
    ? formattedValue[1]
    : "(" +
    formattedValue[1] +
    ") " +
    formattedValue[2] +
    (formattedValue[3] ? "-" + formattedValue[3] : "");
  city = userData.parent_id ? userData.parent_city : userData.user_detail.city;
  userId = userData.id;
  channel = localStorage.getItem("room")
  // channel = userData.user_detail.licensed
  //   ? process.env.VUE_APP_JANUS_CHANNEL_LICENSED
  //   : process.env.VUE_APP_JANUS_CHANNEL_NON_LICENSED;
}

const roomInfo = { [channel]: "redline" };
const roomArray = { redline: channel };
const mixArray = {};

//This bool var is used when MC is not available and User has single chapter
//so we don't need individual flag to maintain mute state
let audioEnabled = false;
let joinedUser = {};
let audioOutputDevices = {};
let audioInputDevices = {};

const maxBroadcastCount = 6;
let currentRedLineBroadcastCount = 0;

(() => {
  if (broadCastingService === -1) {
    return;
  }
  console.re.log("Active server Url - " + janus_url);

  window.addEventListener("offline", function (event) {
    //console.re.error("=================" + username + " Internet lost connection=================");
  });

  window.addEventListener("online", function (event) {
    //console.re.log("=================" + username + " Internet now back online. Reloading page=================");
    window.location.reload();
  });

  document.addEventListener("keydown", function (event) {
    if (
      event.ctrlKey &&
      (event.keyCode == 76 ||
        event.keyCode == 66 ||
        event.keyCode == 89 ||
        event.keyCode == 78)
    ) {
      event.preventDefault();
      const roomActive = channel;

      //Need to find out current broadcaster in single line

      //We have to check this condition when user is trying to broadcast
      if (currentRedLineBroadcastCount >= maxBroadcastCount && !audioEnabled) {
        alert("Broadcast Number crossed the max limit");
        return;
      }

      console.log(
        "Ctrl+L pressed " +
        audioEnabled +
        " user " +
        username +
        " caller ID " +
        userId +
        " Room " +
        roomActive
      );

      if (!audioEnabled) {
        //Enable the Audio

        mixArray[roomActive].send({
          message: {
            request: "configure",
            muted: audioEnabled,
          },
        });

        audioEnabled = true;

        add_div_to_grid_caller(
          "caller" + roomActive + userId,
          `<div><span style="display:none">${userId}</span>
                      <div class="icon_info">
                        <div class="d-flex"><h3>${username}</h3></div>
                        <p>${location} ${city}</p>
                        <p>${phone} ${mobile}</p>
                      </div></div>`,
          roomActive
        );
        console.re.log("Add Caller Div of User " + username);
      } else {
        //Disable the Audio

        mixArray[roomActive].send({
          message: {
            request: "configure",
            muted: audioEnabled,
          },
        });
        audioEnabled = false;
        remove_div_from_grid_caller("caller" + roomActive + userId, roomActive);
        console.re.log("Remove Caller Div of User " + username);
      }
    }
  });

  //Make an Collection of Audio output and intput devices to get
  // them from label later
  Window.Janus.init({
    debug: "all",
    callback: function () {
      Window.Janus.listDevices(function (devices) {
        devices.forEach(function (device) {
          if (device.kind == "audiooutput") {
            audioOutputDevices[device.label] = device.deviceId;
          }

          if (device.kind == "audioinput") {
            audioInputDevices[device.label] = device.deviceId;
          }
        });

        for (let key in roomArray) {
          janusCalling(
            parseInt(roomArray[key]),
            username,
            userId,
            audioEnabled,
            mixArray
          );
        }
      });
    },
  });

  function janusCalling(myroom, username, userId, audioEnabled, mixArray) {
    const server = janus_url;
    const opaqueId = "audiobridgetest-" + Window.Janus.randomString(12);
    let webrtcUp = false;
    let register;
    let janus = null;
    let mixertest = null;
    let myid = null;
    console.re.log("Janus Calling Method Called for User " + username);
    // Initialize the library (all console debuggers enabled)
    Window.Janus.init({
      debug: "all",
      callback: function () {
        // Use a button to start the demo
        // Make sure the browser supports WebRTC
        if (!Window.Janus.isWebrtcSupported()) {
          alert("No WebRTC support... ");
          //console.re.error("=================" + username + " Dont have WebRTC support.=================");
          return;
        }
        // Create session
        janus = new Window.Janus({
          server: server,
          success: function () {
            //hide Offline js
            setTimeout(function () {
              if (Offline.state != "up") {
                console.re.log(
                  "Connected to Janus, Remove Offline Js UI for user " +
                  username
                );
                Offline.options.checks.active = "up";
                Offline.check();
              }
            }, 2000);

            // Attach to Audio Bridge test plugin
            janus.attach({
              plugin: "janus.plugin.audiobridge",
              opaqueId: opaqueId,
              success: function (pluginHandle) {
                mixertest = pluginHandle;
                mixArray[myroom] = mixertest;
                Window.Janus.log(
                  username +
                  " Plugin attached (" +
                  mixertest.getPlugin() +
                  ", id=" +
                  mixertest.getId() +
                  ")"
                );
                // Prepare the username registration
                register = {
                  request: "join",
                  room: myroom,
                  display:
                    `<div><span style="display:none">${userId}</span>
                      <div class="icon_info">
                        <div class="d-flex"><h3>${username}</h3></div>
                        <p>${location} ${city}</p>
                        <p>${phone} ${mobile}</p>
                      </div></div>`,
                };
                mixertest.send({
                  message: register,
                });
              },
              error: function (error) {
                Window.Janus.error("  -- Error attaching plugin...", error);
                console.re.log(error, "occured for user " + username);
                console.log(error, "occured for user " + username);
                alert("Error attaching plugin... " + error);
              },
              consentDialog: function (on) {
                Window.Janus.debug(
                  "Consent dialog should be " + (on ? "on" : "off") + " now"
                );
              },
              onmessage: function (msg, jsep) {
                const event = msg["audiobridge"];
                console.log("room", msg);
                const room_info = msg["room"];

                if (event != undefined && event != null) {
                  if (event === "joined") {
                    myid = msg["id"];
                    if (!webrtcUp) {
                      webrtcUp = true;
                      // Publish our stream
                      mixertest.createOffer({
                        media: {
                          video: false,
                        }, // This is an audio only room
                        success: function (jsep) {
                          var publish = {
                            request: "configure",
                            muted: !audioEnabled,
                          };
                          mixertest.send({
                            message: publish,
                            jsep: jsep,
                          });
                        },
                        error: function (error) {
                          Window.Janus.error("WebRTC error:", error);
                          console.re.log("error occurs for user " + username);
                          console.log("error occurs for user " + username);
                          const myArray = [
                            1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000,
                            9000, 10000,
                          ];
                          const rand =
                            myArray[Math.floor(Math.random() * myArray.length)];
                          setTimeout(function () {
                            window.location.reload();
                          }, rand);
                        },
                      });
                    }
                    // Any room participant?
                    if (
                      msg["participants"] !== undefined &&
                      msg["participants"] !== null
                    ) {
                      const list = msg["participants"];

                      for (let f in list) {
                        const id = list[f]["id"];
                        const display = list[f]["display"];
                        const setup = list[f]["setup"];
                        const muted = list[f]["muted"];

                        //here we get mute and unmute event
                        //Basically this event get called in every change in user json

                        if (setup === true || setup === "true") {
                          if (muted === false || muted === "false") {
                            //Add to the caller grid, as user is unmute
                            add_div_to_grid_caller(
                              "caller" + room_info + id,
                              display,
                              room_info
                            );
                          } else {
                            //Remove from caller grid, as user is mute
                            remove_div_from_grid_caller(
                              "caller" + room_info + id,
                              room_info
                            );
                          }
                        }

                        //Add user online grid here
                        //hiding this in live as we dont need online user cell in live panel.
                        // add_div_to_grid("user" + id, display, room_info);
                        joinedUser[id] = display;
                      }
                    }
                  } else if (event === "destroyed") {
                    // The room has been destroyed
                    //Window.Janus.warn("The room has been destroyed!");
                    alert("The room has been destroyed", function () {
                      window.location.reload();
                    });
                  } else if (event === "event") {
                    if (
                      msg["participants"] !== undefined &&
                      msg["participants"] !== null
                    ) {
                      const list = msg["participants"];
                      for (let f in list) {
                        const id = list[f]["id"];
                        const display = list[f]["display"];
                        const setup = list[f]["setup"];
                        const muted = list[f]["muted"];

                        //here we get mute and unmute event
                        //Basically this event get called in every change in user json

                        if (muted === false || muted === "false") {
                          //Add to the caller grid, as user is unmute
                          add_div_to_grid_caller(
                            "caller" + room_info + id,
                            display,
                            room_info
                          );
                        } else {
                          //Remove from caller grid, as user is mute
                          remove_div_from_grid_caller(
                            "caller" + room_info + id,
                            room_info
                          );
                        }
                      }
                    } else if (
                      msg["error"] !== undefined &&
                      msg["error"] !== null
                    ) {
                      if (msg["error_code"] === 485) {
                        // This is a "no such room" error: give a more meaningful description
                        alert(
                          "<p>Apparently room <code>" +
                          myroom +
                          "</code> (the one this demo uses as a test room) " +
                          "does not exist...</p><p>Do you have an updated <code>Window.Janus.plugin.audiobridge.cfg</code> " +
                          "configuration file? If not, make sure you copy the details of room <code>" +
                          myroom +
                          "</code> " +
                          "from that sample in your current configuration file, then restart Janus and try again."
                        );
                      } else {
                        alert(msg["error"]);
                      }
                      return;
                    }
                    // Any new feed to attach to?
                    if (
                      msg["leaving"] !== undefined &&
                      msg["leaving"] !== null
                    ) {
                      // One of the participants has gone away?
                      const leaving = msg["leaving"];
                      Window.Janus.log(
                        "Participant left: " +
                        leaving +
                        " (we have " +
                        "0" +
                        " elements with ID #rp" +
                        leaving +
                        ")"
                      );

                      // remove_div_from_grid("user" + leaving);
                      remove_div_from_grid_caller(
                        "caller" + room_info + leaving,
                        room_info
                      );
                    }
                  }
                }
                if (jsep !== undefined && jsep !== null) {
                  Window.Janus.debug("Handling SDP as well...");
                  Window.Janus.debug(jsep);
                  mixertest.handleRemoteJsep({
                    jsep: jsep,
                  });
                }
              },
              onlocalstream: function () { },
              onremotestream: function (stream) {
                //Add Caller div here and attach media to it, Since there is going to one remote stream
                //Attach it to any div
                const audioElement = document.getElementById("test");
                if (audioElement) {
                  audioElement.muted = false; //If MC is enable, keep this element muted, unless unmute it
                  Window.Janus.attachMediaStream(audioElement, stream);
                }
                return;
              },
              oncleanup: function () {
                webrtcUp = false;
                Window.Janus.log(" ::: Got a cleanup notification :::");
                console.re.error(username + " WebRtc Platform error oncleanup");
              },
            });
          },
          error: function (error) {
            Window.Janus.error(error);

            setTimeout(function () {
              if (Offline.state != "down") {
                console.re.log(
                  "Error in connecting to Janus, Show Offline Js UI !!"
                );
                Offline.options.checks.active = "down";
                Offline.check();
              }
            }, 2000);
          },
          destroyed: function () {
            window.location.reload();
          },
        });
      },
    });
  }

  function add_div_to_grid_caller(divId, name, room_info) {
    const grid = document.getElementById("caller_grid");
    const findDiv = document.getElementById(divId + "_container");
    let roomBtnColors = 'red accent-4';
    if (grid && !grid.contains(findDiv)) {
      let newDiv = document.createElement("div");
      if (room_info == '123456711') {
        roomBtnColors = 'green accent-4';
      } else if (room_info == '123456712') {
        roomBtnColors = 'brown darken-1';
      } else if (room_info == '123456703') {
        roomBtnColors = 'yellow darken-4';
      } else if (room_info == '123456705') {
        roomBtnColors = 'orange darken-4';
      } else {
        roomBtnColors = 'red accent-4';
      }
      newDiv.setAttribute("id", divId + "_container");
      newDiv.className =
        newDiv.className +
        "live-user-box " + roomBtnColors;

      let newDiv2 = document.createElement("span");
      newDiv2.setAttribute("id", divId);
      newDiv2.className = newDiv2.className + "rd-card-custom ";
      newDiv.appendChild(newDiv2);
      newDiv2.innerHTML = name;

      grid.appendChild(newDiv);

      if (roomInfo[room_info] == "redline") {
        currentRedLineBroadcastCount = currentRedLineBroadcastCount + 1;
      }
    }
  }

  function remove_div_from_grid_caller(divId, room_info) {
    const grid = document.getElementById("caller_grid");
    const childDiv = document.getElementById(divId + "_container");
    if (grid && grid.contains(childDiv)) {
      grid.removeChild(childDiv);
      if (roomInfo[room_info] == "redline") {
        if (currentRedLineBroadcastCount > 0)
          currentRedLineBroadcastCount = currentRedLineBroadcastCount - 1;
      }
    }
  }

  console.re.log(Offline);

  Offline.options = {
    checks: {},
    // Should we check the connection status immediatly on page load.
    checkOnLoad: false,

    // Should we monitor AJAX requests to help decide if we have a connection.
    interceptRequests: false,

    // Should we automatically retest periodically when the connection is down (set to false to disable).
    reconnect: {
      // How many seconds should we wait before rechecking.
      initialDelay: 30,

      // How long should we wait between retries.
      // delay: (30, 3600)
    },

    // Should we store and attempt to remake requests which fail while the connection is down.
    requests: true,

    // Should we show a snake game while the connection is down to keep the user entertained?
    // It's not included in the normal build, you should bring in js/snake.js in addition to
    // offline.min.js.
    game: false,
  };

  Offline.on("reconnect:started", function () {
    console.re.log("Reconnecting Process started !!");
  });
  Offline.on("reconnect:connecting", function () {
    console.re.log("Reconnecting Now !!");
    for (let key in roomArray) {
      janusCalling(
        parseInt(roomArray[key]),
        username,
        userId,
        audioEnabled,
        mixArray
      );
    }
  });
})();
